@import "~antd/dist/antd.css";

.fjPCzc {
    opacity: 0.5
}

.readNotificationTitle {
    color: lightgray;

}

.unReadNotificationTitle {
    /* color: black; */
}

/* .bg-gray ~ .parent{
    background: lightgray
} */
.fs-13 {
    font-size: 13px;
}

.mb-0 {
    margin-bottom: 0px;
}

.d-flex {
    display: flex;
}

.pl-23 {
    padding-left: 23px;
}

.tox .tox-edit-area__iframe {
    background-color: #f5f5f5 !important;
    border: 0;
    box-sizing: border-box;
    flex: 1;
    height: 100%;
    position: absolute;
    width: 100%;
}

.MuiGrid-container {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    box-sizing: border-box;
    margin-bottom: "15px" !important;
}
.ck-editor__main>div{
    min-height: 400px;
}
/* .MuiIconButton-edgeEnd{
    margin-right:'0px' !important;
      } */